customized-filter md-input-container{
    margin-top: 0px;
    margin-bottom: 0px;
}
customized-filter #customized-filter-header:focus{
    outline: none;
}

.customized-filter-row button{
    margin-top: 0px !important;
    margin-bottom: 16px !important;
}

.customized-filter-row md-input-container{
    margin: 0px !important;
}

.customized-filter md-input-container{
    margin: 0px !important;
}

.customized-filter md-input-container .md-errors-spacer{
    min-height: 0px !important
}

.customized-filter button{
    margin: 0px !important;
}

.customized-filter md-radio-button{
    margin-bottom: 0px !important;
}

.customized-filter .indent-space{
    width: 32px;
}



.customized-filter-open-icon{
    position: absolute;
    top: -18px;
    right: -14px;
}

customized-filter-field {
    height: 56px;
}

.measure-unit-row{
    width: 200px;
}

.measure-unit-row md-input-container{
    margin: 0;
}

.measure-unit-column{
    min-width: 200px;
    max-width: 70%;
    margin-left: 40px;
}