.uppercase {
	text-transform: uppercase;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-transform-none {
	text-transform: none !important;
}

.f-s-10 {
	font-size: 10px;
}

.f-s-12 {
	font-size: 12px !important;
}

.f-s-13 {
	font-size: 13px !important;
}

.f-s-14 {
	font-size: 14px;
}

.f-s-15 {
	font-size: 15px;
}

.f-s-16 {
	font-size: 16px;
}

.f-s-16-i {
	font-size: 16px !important;
}

.f-s-18 {
	font-size: 18px;
}

.f-s-20 {
	font-size: 20px !important;
}

.f-s-21-i {
	font-size: 21px !important;
}

.f-s-24 {
	font-size: 24px !important;
}

.f-s-25 {
	font-size: 25px;
}

.f-s-40 {
	font-size: 40px;
}

.f-s-50-i {
	font-size: 50px !important;
}

.no-padding {
	padding: 0px !important;
}

.p-2 {
	padding: 2px;
}

.p-5 {
	padding: 5px;
}

.p-5-30 {
	padding: 5px 30px;
}

.p-8 {
	padding: 8px;
}

.p-10 {
	padding: 10px;
}

.p-10-40 {
	padding: 0px 40px 10px 0px;
}

.p-16 {
	padding: 16px;
}

.p-16-0 {
	padding: 0px 16px 0px 16px;
}

.p-20 {
	padding: 20px;
}

.p-24 {
	padding: 24px;
}

.p-0-4 {
	padding: 0 4px;
}

.p-0-16 {
	padding: 0px 16px;
}

.p-l-0 {
	padding-left: 0px !important;
}

.p-l-4 {
	padding-left: 4px;
}

.p-l-5-i {
	padding-left: 5px !important;
}

.p-l-10 {
	padding-left: 10px !important;
}

.p-l-15 {
	padding-left: 15px;
}

.p-l-15-i {
	padding-left: 15px !important;
}

.p-l-16 {
	padding-left: 16px;
}

.p-l-20 {
	padding-left: 20px !important;
}

.p-l-30 {
	padding-left: 30px !important;
}

.p-l-r-10 {
	padding-left: 10px;
	padding-right: 10px;
}

.p-l-r-30 {
	padding: 0 30px;
}

.p-l-r-50 {
	padding: 0 50px;
}

.p-r-4 {
	padding-right: 4px;
}

.p-r-5-i {
	padding-right: 5px !important;
}

.p-r-10 {
	padding-right: 10px;
}

.p-r-20 {
	padding-right: 20px;
}

.p-r-30 {
	padding-right: 30px;
}

.p-r-50 {
	padding-right: 50px;
}

.p-t-0 {
	padding-top: 0px !important;
}

.p-t-2 {
	padding-top: 2px;
}

.p-t-3 {
	padding-top: 3px !important;
}

.p-t-5 {
	padding-top: 5px;
}

.p-t-8 {
	padding-top: 8px !important;
}

.p-t-10 {
	padding-top: 10px !important;
}

.p-t-15 {
	padding-top: 15px;
}

.p-t-16 {
	padding-top: 16px;
}

.p-t-18 {
	padding-top: 18px !important;
}

.p-t-20 {
	padding-top: 20px;
}

.p-t-30-i {
	padding-top: 30px !important;
}

.p-t-48 {
	padding-top: 48px;
}

.p-b-0 {
	padding-bottom: 0;
}

.p-b-10 {
	padding-bottom: 10px;
}

.p-b-20 {
	padding-bottom: 20px;
}

.p-b-22 {
	padding-bottom: 22px;
}

.p-b-30-i {
	padding-bottom: 30px !important;
}

.p-b-45 {
	padding-bottom: 45px;
}

.p-b-t-0 {
	padding-bottom: 0;
	padding-top: 0;
}

.m-t--30 {
	margin-top: -30px;
}

.m-t--20 {
	margin-top: -20px;
}

.m-t-0 {
	margin-top: 0px !important;
}

.m-t-2 {
	margin-top: 2px !important;
}

.m-t-3 {
	margin-top: 3px !important;
}

.m-t-4 {
	margin-top: 4px;
}

.m-t-5 {
	margin-top: 5px;
}

.m-t-7 {
	margin-top: 7px !important;
}

.m-t-8 {
	margin-top: 8px;
}

.m-t-10 {
	margin-top: 10px !important;
}

.m-t-15 {
	margin-top: 15px !important;
}

.m-t-16 {
	margin-top: 16px;
}

.m-t-18 {
	margin-top: 18px;
}

.m-t-20 {
	margin-top: 20px !important;
}

.m-t-24 {
	margin-top: 24px !important;
}

.m-t-27 {
	margin-top: 27px;
}

.m-t-28 {
	margin-top: 28px !important;
}

.m-t-32 {
	margin-top: 32px !important;
}

.m-t-150 {
	margin-top: 150px;
}

.m-t--3-i {
	margin-top: -3px !important;
}

.m-l-0 {
	margin-left: 0px !important;
}

.m-l-5 {
	margin-left: 5px;
}

.m-l-8 {
	margin-left: 8px !important;
}

.m-l-10 {
	margin-left: 10px !important;
}

.m-l-16 {
	margin-left: 16px !important;
}

.m-l-20 {
	margin-left: 20px !important;
}

.m-l-24 {
	margin-left: 24px;
}

.m-l-30 {
	margin-left: 30px;
}

.m-l-35 {
	margin-left: 35px;
}

.m-l-r-8 {
	margin: 0px 8px;
}

.m-r-2 {
	margin-right: 2px;
}

.m-r-5 {
	margin-right: 5px !important;
}

.m-r-8 {
	margin-right: 8px;
}

.m-r-10 {
	margin-right: 10px !important;
}

.m-r-16 {
	margin-right: 16px;
}

.m-r-20 {
	margin-right: 20px;
}

.m-r-24 {
	margin-right: 24px;
}

.m-r-50 {
	margin-right: 50px;
}

.m-b-0 {
	margin-bottom: 0;
}

.m-b-0-i {
	margin-bottom: 0 !important;
}

.m-b-4 {
	margin-bottom: 4px;
}

.m-b-5 {
	margin-bottom: 5px !important;
}

.m-b-8 {
	margin-bottom: 8px !important;
}

.m-b-10 {
	margin-bottom: 10px !important;
}

.m-b-12 {
	margin-bottom: 12px;
}

.m-b-15 {
	margin-bottom: 15px;
}

.m-b-16 {
	margin-bottom: 16px;
}

.m-b-16-i {
	margin-bottom: 16px !important;
}

.m-b-20 {
	margin-bottom: 20px !important;
}

.m-b-24 {
	margin-bottom: 24px;
}

.m-b--10 {
	margin-bottom: -10px;
}

.m-b--30 {
	margin-bottom: -30px !important;
}

.m-b-20-i md-input-container {
	margin-bottom: 20px !important;
}

.m-0-8 {
	margin: 0 8px 0 8px !important;
}

.m-0-16 {
	margin: 0 16px;
}

.no-margin {
	margin: 0 !important;
}

.m-5 {
	margin: 5px;
}

.m-8 {
	margin: 8px;
}

.m-8-0 {
	margin: 8px 0px;
}

.m-10 {
	margin: 12px;
}

.m-15 {
	margin: 15px;
}

.m-16 {
	margin: 16px;
}

.m-16-0 {
	margin: 16px 0;
}

.m-t-10cent {
	margin-top: 10%;
}

.m-w-140 {
	min-width: 140px !important;
}

.min-w-160 {
	min-width: 160px;
}

.m-w-250 {
	min-width: 250px !important;
}

.max-w-700 {
	max-width: 700px;
}

.max-width-100-i {
	max-width: 100% !important;
}

.min-height-48 {
	min-height: 48px;
}

.min-height-142 {
	min-height: 142px !important;
}

.min-h-178 {
	min-height: 178px;
}

.opacity-0 {
	opacity: 0;
}

.opacity-1 {
	opacity: 1;
}

.bold {
	font-weight: bold;
}

.font-bold {
	font-weight: bold;
}

.f-w-500 {
	font-weight: 500;
}

.f-w-n {
	font-weight: 300;
}

.f-w-no {
	font-weight: normal;
}

.display-inline {
	display: inline;
}

.display-block {
	display: block;
}

.display-inline-block {
	display: inline-block;
}

.display-flex {
	display: flex !important;
}

.pull-right {
	float: right;
}

.text-align-center {
	text-align: center;
}

.text-align-end {
	text-align: end;
}

.justify {
	text-align: justify;
}

.align-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.cursor-unset {
	cursor: unset;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default !important;
}

.height-auto {
	height: auto;
}

.height-105 {
	height: 105px;
}

.h-0 {
	height: 0px;
}

.remove-default-color {
	color: inherit;
}

.transparent {
	color: transparent;
}

.text-underline {
	text-decoration: underline !important;
}

.text-italic {
	font-style: italic;
}

.t-t-c {
	text-transform: capitalize;
}

.w-100 {
	width: 100%;
}

.w-72 {
	width: 72px;
}

.width-auto {
	width: auto;
}

.full-width {
	width: 100%;
}

.w-52-p {
	width: 52%;
}

.w-56-p {
	width: 56%;
}

.set-width-50 {
	width: 50%;
}

.overflow-x-hidden {
	overflow-x: hidden;
}

.overflow-auto {
	overflow: auto;
}

.outline-none {
	outline: none;
}

.absolute {
	position: absolute;
}

.relative {
	position: relative;
}

.bottom-66 {
	bottom: 66px;
}

.bottom-78 {
	bottom: 78px;
}

.bottom-83 {
	bottom: 83px;
}

.left-94-p {
	left: 94%;
}

.left-4 {
	left: 4px;
}

.left-95-p {
	left: 95%;
}

.z-index-58 {
	z-index: 58;
}

.pointer-events-none {
	pointer-events: none;
}

.align-self-center {
	align-self: center;
}

.box-shadow-none {
	box-shadow: none;
}

.align-items-end {
	align-items: end;
}

.gap-8 {
	gap: 8px;
}

.gap-16 {
	gap: 16px;
}


.width-fit-content {
	width: fit-content;
}

.primary-color {
	color: #1f4b60 !important;
}

.max-height-70 {
	max-height: 70px !important;
}
