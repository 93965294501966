@import "../default-config.scss";

$menu-background-color: $primary-color;
$menu-item-lock-color: $light-green;
$menu-group-background-color: $primary-color-fade;
$menu-header-separator-color: rgba(255, 255, 255, 0.5);
$menu-new-item-text-color: rgba(0, 0, 0, 0.5);
$menu-beta-item-text-color: rgb(255, 255, 255);
$menu-button-active-color: rgba(236, 236, 236, 0.2);

.md-sidenav {
	width: 100%;
	min-width: 200px;
	max-width: 400px;
	background-color: $menu-background-color;

	.close-side-nav {
		button {
			max-width: 32px;
			width: 32px;
			min-width: 32px;
			max-height: 32px;
			height: 32px;
			min-height: 32px;
			padding: 0;
			margin: 8px;
			color: white;
		}

		em {
			margin: 12px 20px;
		}
	}

	.user-edit-row {
		font-size: 18px;

		em {
			border: solid 1px white;
			border-radius: 5px;
			padding: 1px;
			margin-left: 5px;
			font-size: 18px;
		}
	}

	.default-bg {
		background-color: $menu-background-color;
	}

	.md-button-toggle > button.active {
		background-color: inherit;
	}

	button.active {
		background-color: $menu-button-active-color;
	}

	:focus {
		outline: none;
	}

	md-icon,
	.material-icons,
	.fa:before,
	span,
	a {
		color: white;
		font-weight: normal;
	}

	md-icon.menu-lock-icon {
		color: $menu-item-lock-color;
		font-size: 16px;
		margin-top: 12px;
		padding-left: 4px;
	}

	.menu-item-first-level {
		padding-left: 32px;

		.side-nav-icon {
			font-size: 18px;
		}
	}

	.menu-item-second-level {
		padding-left: 64px;

		.side-nav-icon {
			font-size: 18px;
		}
	}

	.menu-item-third-level {
		padding-left: 96px;

		.side-nav-icon {
			font-size: 18px;
		}
	}

	.md-button-toggle span {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.menu-toggle-list {
		overflow: hidden;
		position: relative;
		z-index: 1;
		-webkit-transition: 0.75s cubic-bezier(0.35, 0, 0.25, 1);
		-moz-transition: 0.75s cubic-bezier(0.35, 0, 0.25, 1);
		transition: 0.75s cubic-bezier(0.35, 0, 0.25, 1);

		a.md-button {
			display: block;
			padding: 0 16px 0 32px;
			text-transform: none;
			text-rendering: optimizeLegibility;
			font-weight: 500;
		}

		.md-button {
			display: block;
			text-transform: none;
		}
	}

	.menu-toggle-list.ng-hide {
		max-height: 0;
	}

	.menu-group {
		background-color: $menu-group-background-color;
		border-radius: 10px;
		margin-right: 5%;
		margin-left: 5%;
		margin-bottom: 9px;
	}

	.side-menu,
	.side-menu ul {
		list-style: none;
		padding: 0;
		margin-top: 0;

		li > div button md-icon {
			margin-left: 13px;
		}

		.md-toggle-icon {
			margin-right: 13px;
		}
	}

	.side-menu {
		.md-button {
			border-radius: 0;
			cursor: pointer;
			font-weight: 400;
			line-height: 40px;
			margin: 0;
			max-height: 40px;
			overflow: hidden;
			text-align: left;
			text-decoration: none;
			white-space: normal;
			width: 100%;
		}
	}

	button > span {
		font-size: 14px;
		text-transform: none;
	}

	button > span.new-menu-item {
		background-color: $blue-color;
		padding: 2px 7px;
		border-radius: 10px;
		color: $menu-new-item-text-color;
		font-size: 10px;
		font-weight: bold;
		text-transform: uppercase;
	}

	button > span.beta-menu-item {
		background-color: $blue-color;
		padding: 2px 7px;
		border-radius: 10px;
		color: $menu-beta-item-text-color;
		font-size: 10px;
		font-weight: bold;
		text-transform: uppercase;
		vertical-align: middle;
	}

	.menu-header-separator {
		background-color: $menu-header-separator-color;
	}

	.menu-button-disabled {
		opacity: 0.7;
	}
	
	.show-beta-color {
		color: $blue-color !important;
		margin-top: 4px;
	}
}

.menu-hover-disable {
	cursor: auto !important;
}

.menu-hover-disable:hover {
	background-color: unset !important;
}

.md-sidenav-left {
	height: 100%;
	overflow: hidden;
	padding-bottom: 75px;

	menu {
		height: 100%;
		margin: 0;
		overflow: auto;
		padding: 0;
	}

	.left-menu-header {
		font-size: 14px;
		line-height: initial;
	}
}

.md-sidenav-right {
	text-align: center;

	.side-nav-icon {
		display: none;
	}

	.tos-menu-right {
		margin-right: 8px;
		padding: 10px 15px 0px 15px;
		font-style: italic;
		font-size: 11px;
	}

	.tos-menu-right a:hover {
		color: white;
	}

	.language-footer {
		display: block;
		padding: 5px;

		button > span {
			font-size: 13px;
		}

		button.off-focus > span {
			font-size: 11px;
			color: $gray-color;
		}

		.md-button {
			text-transform: none;
			border-radius: 10px;
			margin: 5px;
		}

		.language-option-visible {
			-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
			-moz-animation: fadein 2s; /* Firefox < 16 */
			animation: fadein 2s;
		}

		.selected {
			background-color: white;

			span {
				color: $menu-background-color;
			}
		}
		em {
			font-size: 14px;
			margin-left: 5px;
		}
	}
}

/* out of md-side-nav because dropdown is constructed outside */
.change-user-menu,
.change-client-menu {
	min-height: 550px;
	max-height: 550px;
	height: 550px;
	width: 850px;
	min-width: 850px;
	max-width: 850px;
	padding-top: 0px;

	.fa-chevron-down {
		padding-top: 10px;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
