@import "../../../css/default-config.scss";

.new-features-popup {
	.main-content {
		width: 958px;
		height: auto;

		img {
			width: 100%;
			object-fit: contain;
		}
	}

	.button-large {
		padding: 2px 15px;
		margin: 0px 6px;
	}

	.footer-content {
		padding: 24px;
		gap: 16px;

		.info-message {
			color: $neutral-dark-color;
			line-height: 24px;
			.know-more {
				font-size: 16px;
			}
			.contact-our-support {
				font-size: 12px;
			}
			.know-more-small {
				font-size: 12px;
			}
			.contact-our-support-large {
				font-size: 16px;
			}
		}
	}
}


@media only screen and (max-width: 1024px) {
	md-dialog.md-dialog-fullscreen {
		min-height: auto !important;
	}
	
	.new-features-popup {
		width: auto;
	}

	.new-features-popup .main-content{
		width: auto;
		height: auto;
	}
}
