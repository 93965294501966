@import "default-config.scss";

.c-green-primary {
	color: $primary-color;
}

.secondary-color {
	color: $secondary-color;
}

.neutral-dark-color {
	color: $neutral-dark-color;
}

td.c-green-primary span {
	color: $strong-green;
}

td.f-bold span {
	font-weight: bold;
}

.strong-green {
	color: $strong-green;
}

.c-blue {
	color: $blue-color;
}

.light-grey {
	color: #d3d3d3;
}

.c-grey {
	color: #9b9b9b !important;
}

.color-dark-grey {
	color: $dark-grey;
}

.c-opaque-black {
	color: #4a4a4a;
}

.c-white {
	color: white;
}

.c-orange {
	color: $orange-color !important;
}

.c-red {
	color: $danger-color !important;
}

td.c-red span {
	color: $danger-color !important;
}

.strong-red {
	color: $danger-color;
}

.text-black-i {
	color: black !important;
}

.stretch-height {
	margin: 0;
	width: 100% !important;
	height: 100% !important;
}

.lf-ng-md-file-input .lf-ng-md-file-input-preview-container .lf-ng-md-file-input-frame > img {
	height: auto !important;
	max-height: 200px !important;
	width: auto !important;
}

.lf-ng-md-file-input .lf-ng-md-file-input-preview-container .lf-ng-md-file-input-frame {
	height: auto !important;
}

.label {
	color: rgba(0, 0, 0, 0.57) !important;
}

.report-filter-content {
	overflow-x: hidden;
	overflow-y: auto;
}

.form-content-border {
	border: 1px solid rgba(155, 155, 155, 0.2);
	border-radius: 5px;
	outline: none;
	position: relative;
	color: #000000;
	transition: filter 1s;
}

button.condensed {
	min-width: 0px !important;
}

.vgr-page-content {
	background: #e8e8e8 !important;
	overflow: auto;
}

.uiview {
	padding: 0 8% 2% 8% !important;
}

.uiview.mr {
	padding: 0px !important;
}

.uiview > md-content {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

grid {
	padding: 0px !important;
}

grid > div {
	padding: 0px !important;
}

.height-unset {
	md-tab-content > div {
		height: unset !important;
		min-height: unset !important;
	}
}

.audit-email-popup-template {
	border: 1px #cecece solid;
	margin-top: 10px;
	padding: 10px;
	pointer-events: none;

	.row {
		margin: 0 !important;
	}
}

@media (min-height: 502px) and (max-height: 627px) {
	.base-popup-height {
		max-height: 400px;
	}
}

@media (min-height: 628px) and (max-height: 751px) {
	.base-popup-height {
		max-height: 500px;
	}
}

@media (min-height: 752px) {
	.base-popup-height {
		max-height: 600px;
	}
}

@media (min-width: 1280px) {
	#large-popup md-dialog {
		min-width: 1180px !important;
		max-width: 1180px !important;
	}
}

@media (min-width: 950px) {
	#supplier-solicitation-popup md-dialog {
		min-width: 730px !important;
		max-width: 930px !important;
		max-height: 540px !important;
		min-height: 540px !important;
	}
}

@media (min-width: 950px) {
	#supplier-solicitation-popup-small md-dialog {
		min-width: 730px !important;
		max-width: 930px !important;
		max-height: 80% !important;
		min-height: 50% !important;
	}
}

#middle-popup md-dialog {
	min-width: 520px !important;
	max-width: 610px !important;
}

#short-popup md-dialog {
	min-width: 360px !important;
	max-width: 500px !important;
}

#audit-popup md-dialog {
	min-width: 360px !important;
	max-width: 500px !important;
}

#audit-attachment-popup md-dialog {
	min-width: 680px !important;
	max-width: 680px !important;
	max-height: 500px !important;
	min-height: 500px !important;
}

#residue-filter-popup md-dialog {
	max-height: 90% !important;
	min-height: 90% !important;
}

.audit-popup {
	padding: 20px 20px 10px 20px;
	overflow: auto;

	md-input-container.md-input-focused label:not(.md-no-float),
	md-input-container.md-input-has-value label:not(.md-no-float) {
		display: none;
	}

	.line-height-18 md-input-container .md-input {
		line-height: 18px !important;
	}
}

.audit-dashbord {
	span {
		font-size: 12px;
		color: #4a4a4a;
	}
}

.audit-box {
	padding: 20px 30px;
	margin: 5px;
	box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
}

.no-md-errors-spacer {
	md-input-container {
		.md-errors-spacer {
			display: none;
		}
	}
}

.no-errors-spacer {
	div.errors {
		display: none;
	}
}

//TODO remove after migrating angular materialjs
.multi-select span .md-container {
	display: none !important;
}

.customized-filters md-autocomplete md-progress-linear {
	bottom: 20px !important;
}

.audit-item {
	padding: 20px;
	margin: 5px;
	border: solid 1px rgba(155, 155, 155, 0.25);
	border-radius: 5px;
	background-color: rgba(208, 208, 208, 0.25);

	span {
		font-size: 12px;
		color: #4a4a4a;
	}

	md-radio-button {
		margin-right: 10px !important;

		.md-container {
			width: 12px !important;
			height: 12px !important;

			.md-off {
				width: 12px !important;
				height: 12px !important;
				border-color: $blue-color-fade !important;
			}
			.md-on {
				width: 12px !important;
				height: 12px !important;
				background-color: $blue-color-fade !important;
			}
		}

		.md-label {
			margin-left: 15px !important;
		}
	}
}

.audit-category-info {
	padding: 15px;

	span {
		margin: 3px 5px;
		font-size: 12px;
		color: #4a4a4a;
	}
}

.grid-bar {
	background: white !important;
	color: black !important;
	z-index: 0 !important;
}

.grid-bar md-icon {
	color: black !important;
}

.grid-bar .title {
	font-size: 20px !important;
}

.grid-bar md-button.active,
.grid-bar button.active {
	background-color: white !important;
}

.grid-bar md-button.active md-icon,
.grid-bar button.active md-icon {
	color: black !important;
}

.grid-bar md-button.active.report,
.grid-bar button.active.report {
	background-color: white !important;
}

.grid-with-scroll {
	max-height: 150px;
	overflow: auto;
}

vgr-container md-tabs {
	border-radius: 0px;
	border-top: 0px !important;
}

md-tabs-wrapper {
	background-color: white !important;
}

md-tab-item span {
	color: black !important;
}

md-tab-item.md-active {
	background-color: white !important;
}

md-tab-item.md-active span {
	color: black !important;
}

md-tab-item:hover {
	background-color: $light-grey;
}

md-tab-item:hover md-tabs md-ink-bar {
	background-color: $light-grey;
}

.message-bar .md-toast-content {
	padding: 0;
	color: white;
}

.message-bar md-icon {
	color: white;
}

md-toast.red-bg .md-toast-content {
	background: $danger-color !important;
}

md-toast.green-bg .md-toast-content {
	background: $success-color !important;
}

.grid-bar md-progress-circular .md-inner .md-right .md-half-circle {
	border-right-color: black;
	border-top-color: black;
}

.grid-bar md-progress-circular .md-inner .md-left .md-half-circle {
	border-left-color: black;
	border-top-color: black;
}

.condensed-header-col,
.condensed-row-col {
	padding: 0 0 0 16px !important;
}

.fileUploadIcon {
	position: relative;
}

.fileUploadIcon:before {
	content: "\E2C6";
	font-family: Material "+" Icons, sans-serif;
	left: -5px;
	position: absolute;
	top: 0;
}

.fileCaptionIcon {
	position: relative;
}

.fileCaptionIcon:before {
	content: "\E24D";
	font-family: Material "+" Icons, sans-serif;
	left: -5px;
	position: absolute;
	top: 0;
}

.fileRemoveIcon {
	position: relative;
}

.fileRemoveIcon:before {
	content: "\E872";
	font-family: Material "+" Icons, sans-serif;
	left: -5px;
	position: absolute;
	top: 0;
}

.fileUnknownIcon {
	position: relative;
}

.fileUnknownIcon:before {
	content: "\E2BC";
	font-family: Material "+" Icons, sans-serif;
	left: -5px;
	position: absolute;
	top: 0;
	font-size: 550%;
}

.filter-bg {
	background-color: white;
	color: black;
}

.filter-bg md-select .md-select-value.md-select-placeholder,
.filter-bg md-input-container:not(.md-input-invalid).md-input-has-value label,
.filter-bg md-select:not([disabled]):focus .md-select-value,
.filter-bg md-input-container .md-input {
	color: black !important;
	border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}

.filter-bg md-input-container label,
md-input-container .md-placeholder {
	color: black !important;
}

.lf-ng-md-file-input {
	margin-bottom: 20px !important;
}

.warn-row {
	background-color: $danger-color;
}

.background-light-orange {
	background-color: $alert-color;
}

.background-grey {
	background-color: rgba(0, 0, 0, 0.05);
}

.white-background {
	background-color: white;
}

.background-white {
	background-color: white;
}

.background-darkgray {
	background-color: rgba(255, 255, 255, 0.3);
}

.request-supplier-popup-title {
	color: #4a4a4a;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
}

.request-supplier-popup-text {
	color: #4a4a4a;
	font-size: 12px;
	font-weight: bold;
}

.mtr-integration-popup-invalid {
	input {
		border: solid 1px $danger-color;
	}
}

.md-chips.filter,
md-chips.filter .md-chips,
md-chips.filter .md-chips.focused {
	box-shadow: none !important;
}

.md-chips.filter input,
md-chips.filter input {
	font-size: 20px;
}

.md-chips.filter md-chip:not(.md-focused),
md-chips.filter md-chip:not(.md-focused) {
	background: white !important;
}
md-chips.filter .md-chip.focused {
	background: grey !important;
}

.md-chips.filter md-chip md-icon,
md-chips.filter md-chip md-icon {
	color: grey !important;
}

md-grid-tile {
	margin-bottom: 5px;
}

.md-pagination-select {
	margin-top: 0px !important;
}

[disabled] md-input-container .md-input,
md-input-container .md-input[disabled],
md-select[disabled] .md-select-value {
	color: #9c9c9c !important;
	background: #f5f5f5;
}

md-autocomplete-wrap md-autocomplete button {
	position: absolute !important;
	right: 0 !important;
}

md-autocomplete-wrap.md-whiteframe-1dp,
md-autocomplete-wrap.md-whiteframe-z1 {
	box-shadow: none !important;
}

.infinite-subfield-list li {
	height: unset !important;
}

textcomplete md-input-container {
	padding-bottom: 0px !important;
}

.search-input {
	width: 100%;
	margin-top: 20px !important;
	height: 40px;
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
}

.search-input:focus {
	outline: none;
}

md-dialog .md-dialog-content {
	padding: 0px !important;
}

div.md-toast-content {
	border-radius: 2px;
}

div.error-message {
	color: $danger-color;
	text-align: center;
}

span.button_group .heading-1,
span.button_group .quote,
span.button_group .code,
span.button_group .nesting-level,
span.button_group .link,
span.button_group .bullets {
	display: none;
}

#status-buttons a {
	display: inline-flex;
}

#status-buttons a.active {
	color: white;
}

#status-buttons a.available {
	background: #80cbc4;
	color: white;
}

#status-buttons .number {
	color: grey;
	position: absolute;
	top: 5px;
	left: 25px;
}

#status-buttons .number-radius {
	display: inline-block;
	margin: 0 10px 10px;
	border-radius: 50%;
	background: white;
	width: 25px;
	height: 25px;
	margin-top: 10px;
}

#status-buttons .text {
	margin-right: 10px;
}

canvas {
	max-height: 800px;
	height: 100% !important;
}

.grid-text-field md-input-container {
	height: 40px !important;
	padding: 0px !important;
}

.grid-text-field md-input-container input {
	height: 40px !important;
}

@media (max-width: 1080px) {
	md-dialog {
		min-width: 50%;
		max-width: 80%;
		max-height: 80%;
	}

	.vgr-page > .md-dialog-container > md-dialog {
		min-width: unset;
	}
}

@media (min-width: 1080px) {
	md-dialog {
		min-width: 50%;
		max-width: 960px;
	}

	.vgr-page > .md-dialog-container > md-dialog {
		min-width: unset;
		max-height: 90%;
	}
}

@media (max-width: 960px) {
	.dashboard-charts {
		min-width: 200px;
		max-width: 80%;
	}
}

.chart-bar {
	max-width: 100% !important;
}

.chart-line {
	max-width: 100% !important;
}

.chart-pie {
	max-width: 100% !important;
}

.chart-doughnut {
	max-width: 100% !important;
}

.information-small {
	background-color: $orange-color;
	padding: 2px 7px !important;
	border-radius: 10px;
	font-size: 10px;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
	width: 600px !important;
}

md-table-pagination {
	background: white !important;
}

.map-map {
	z-index: 1;
}

.rating-request {
	label {
		font-size: 14px !important;
		color: rgba(0, 0, 0, 0.54) !important;
	}
	.rating-client {
		height: 50px !important;
	}
}

.delete-number {
	color: black;
}

md-chips-wrap {
	width: 100% !important;
	.md-chip-input-container {
		width: 70% !important;
	}
}

v-accordion {
	.md-input {
		color: black !important;
	}
}

.confirmation {
	width: 500px !important;
	min-width: 5% !important;
}

.md-select-value :first-child {
	-webkit-transform: none !important;
	transform: none !important;
}

@media screen and (min-width: 0\0) {
	.ie-hack {
		min-width: 90%;
	}
}

.mr-message-card-title {
	height: 30px;
	padding: unset;
}

.no-outline {
	outline: 0px dashed;
}

.align-items-center {
	align-items: center;
}

.autocomplete-loading {
	top: 20px;
	left: 90%;
	height: 0px !important;
}

.search-item-outer-div {
	background-color: white;
	border-bottom: 1px solid #d8dfe6;
}

.image-supplier-list {
	display: block !important;
	width: 100% !important;
	height: auto !important;
	min-height: 0px !important;
}

.image-croped {
	object-fit: cover;
	width: 102px;
	height: 102px;
}

.image-croped-120 {
	object-fit: cover;
	width: 120px;
	height: 120px;
}

.image-croped-small {
	object-fit: cover;
	width: 80px;
	height: 80px;
}

.image-croped-opacity {
	object-fit: cover;
	width: 102px;
	height: 102px;
	opacity: 0.5;
}

.align-center {
	margin: 0 auto;
}

.eye-in-field {
	position: relative;
	padding-right: 4px;
	bottom: 35px;
	font-size: 20px !important;
}

.base {
	width: 256px;
	height: 32px;
	background-color: rgba(255, 255, 255, 0);
}

.switch-on-filter {
	margin: 0px;
	margin-top: 23px;
	color: #4a4a4a;
	font-size: 12px;
	height: 14px;
}
.switch-on-filter-no-margin {
	color: #4a4a4a;
	font-size: 12px;
	height: 14px;
}

.blocks-border {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
	border-radius: 2px;
}

.blocks-border-for-grid-content {
	box-shadow: 0 -0.1px 3px 0 rgba(0, 0, 0, 0.2), 0 0px 1px 0 rgba(0, 0, 0, 0.14), 0 0px 1px 0px rgba(0, 0, 0, 0.12);
	border-radius: 2px;
	border-bottom: 0px;
}

.blocks-border-for-grid-pagination {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
	border-radius: 2px;
	border-top: 0px;
}

.filters-report-buttons-right {
	width: 30%;
	float: right;
	text-transform: uppercase;
}

.border-top-filters {
	border-top: 1px solid rgba(34, 34, 34, 0.041);
}

.access-market-btn {
	margin-right: 16px !important;
	margin-left: 14% !important;
}

/* 
    *  =================== md-switch parameters  ==========================
*/

// The bar:
md-switch .md-bar {
	background-color: rgba(155, 155, 155, 0.5); /* set not selected bar color */
}

md-switch.md-checked .md-bar {
	background-color: #0096875b; /* set selected bar color */
}

md-switch[disabled] .md-bar {
	background-color: rgba(51, 51, 51, 0.15); /* set disabled bar color */
}

// the switch
md-switch .md-thumb {
	color: #807d7d; /* not selected switch color */
}

md-switch.md-checked .md-thumb {
	background-color: $primary-color; /* selected switch color */
}

md-switch[disabled] .md-thumb {
	background-color: #9b9b9b; /* disabled switch color */
}

// ripple effect
md-switch .md-ink-ripple {
	color: #9b9b9b; /* not selected switch ripple color */
}
md-switch.md-checked .md-ink-ripple {
	color: $primary-color; /* selected switch ripple color */
}

md-switch.md-default-theme.md-checked .md-thumb,
md-switch.md-checked .md-thumb {
	background-color: $primary-color;
}

/* ================================================= */

.fade.ng-hide-remove,
.fade.ng-hide-add {
	display: block !important; /* or inline-block, as appropriate */
}

.welcome-loading {
	margin-right: 11px;
	margin-left: -30px;
	margin-top: 2px;
	width: 19px !important;
	float: left;
}

.product-popup {
	width: 650px !important;
	min-width: 5% !important;
	min-height: 50% !important;
}

.no-bg-btn {
	background-color: transparent !important;
	box-shadow: none !important;
}

.no-bg-btn:active {
	background-color: #ddd;
	box-shadow: none !important;
}

.form-loading {
	left: calc(50% - 75px);
	top: 100px;
}

.page-blur > * {
	filter: blur(3px);
}

.audit-plan-box-container {
	box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);

	.audit-plan-box {
		box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);

		div {
			outline: none;
		}
	}

	.audit-plan-box:hover {
		background-color: $light-grey;
	}
}

.content-nav-bar {
	background: #e8e8e8 !important;
	height: 58px;
	padding: 10px 8% 10px 8% !important;

	.nav-bar-toolbar {
		height: 32px;
		max-height: 32px;
		min-height: 32px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05);
		background-color: white;
		border-image-source: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0.12),
			rgba(255, 255, 255, 0.06) 20%,
			rgba(255, 255, 255, 0)
		);
		border-image-slice: 1;
		border-radius: 4px;
		margin: 0 5px 0 5px;
		z-index: 0;
	}

	.content-nav-bar-navigate-back {
		color: $secondary-color;
		outline: 0px dashed;
	}

	.content-nav-bar-separator {
		font-size: 20px;
		padding-top: 2px;
	}

	.nav-bar-toolbar > div {
		padding: 0;
	}

	.nav-bar-grey {
		color: #9b9b9b;
	}

	.nav-bar-button {
		height: 32px;
		max-height: 32px;
		min-height: 32px;
		min-width: 98px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05);
		background-color: white;
		border-image-source: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0.12),
			rgba(255, 255, 255, 0.06) 20%,
			rgba(255, 255, 255, 0)
		);
		border-image-slice: 1;
		border-radius: 4px;
		margin: 0 5px 0 5px;
	}

	.nav-bar-button-hide {
		height: 32px;
		max-height: 32px;
		min-height: 32px;
		min-width: 32px;
		width: 32px;
		max-width: 32px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05);
		background-color: white;
		border-image-source: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0.12),
			rgba(255, 255, 255, 0.06) 20%,
			rgba(255, 255, 255, 0)
		);
		border-image-slice: 1;
		border-radius: 4px;
		margin: 0 5px 0 5px;
	}

	.content-buttons {
		button:first-child {
			height: 32px;
			max-height: 32px;
			min-height: 32px;
			min-width: 98px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05);
			border-image-source: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0.12),
				rgba(255, 255, 255, 0.06) 20%,
				rgba(255, 255, 255, 0)
			);
			border-image-slice: 1;
			border-radius: 4px;
			margin: 0 5px 0 5px;
			line-height: inherit;
		}
	}

	input {
		font-size: 13px;
		border-radius: 4px !important;
	}

	.material-icons {
		font-size: 21px;
		margin-left: 0;
		margin-right: 2px;
	}

	.search-bar {
		.material-icons {
			font-size: 18px;
			margin-left: 8px;
			margin-right: 0px;
		}
	}

	.input-container {
		md-chips {
			md-chips-wrap > div {
				line-height: inherit !important;
				width: 90% !important;
			}
		}
	}

	md-input-container > md-select > .md-select-value > span > div {
		font-size: 13px !important;
	}

	.content-nav-bar-filter-button {
		button.md-button.nav-bar-button {
			background-color: transparent;
			border: none;
			box-shadow: none !important;
			color: $primary-color;
		}
	}

	.content-nav-bar-tag-list {
		max-width: 180px;
		md-chips-wrap > div > md-autocomplete {
			min-width: 0;
		}
		md-icon {
			color: rgba(0, 0, 0, 0.54) !important;
		}
	}

	.content-nav-bar-date {
		md-icon {
			color: rgba(0, 0, 0, 0.54);
		}

		md-select-value {
			padding-top: 0;
		}

		.content-nav-bar-date-input {
			label {
				font-size: 13px;
				padding-bottom: 6px;
			}
		}
		.content-nav-bar-date-input.md-input-focused {
			label {
				display: none;
			}
		}
		.content-nav-bar-date-input.md-input-has-value {
			label {
				display: none;
			}
		}

		.date-filter-grid {
			color: black;
		}
	}

	.md-select-value .md-select-icon:after {
		transform: scaleY(0.3) scaleX(0.6) !important;
	}

	.hide-chips-input {
		input {
			display: none;
		}
		md-icon.material-icons {
			margin-top: 7px !important;
			margin-bottom: 0 !important;
		}
	}

	md-chips {
		span {
			font-size: 13px !important;
		}
	}
}

.go-back-tab-button {
	background: transparent;
	border: none;
	margin-left: 16px;

	span {
		color: black !important;
	}

	md-icon {
		color: black !important;
	}
}

.audit-classification-img {
	max-height: 20px;
	margin-right: 5px;
}

.icon-resize {
	width: 19px;
	height: 20px;
}

@media screen and (min-width: 1450px) {
	.m-t-10cent {
		margin-top: 68%;
	}
	.set-height-on-big-screen {
		height: 110% !important;
	}
}

.base-page {
	width: 464px;
	min-height: 100%;
}

@media (max-width: 600px) {
	.base-page {
		width: 90%;
	}
}

.base-page-box {
	border-top: 2px solid $primary-color;
	border-radius: 2px;
	box-shadow: 0 4px 8px 0 rgba(34, 34, 34, 0.2);
	width: 100%;
	height: auto;
	padding: 40px;
}

.base-page-content {
	padding: 0px 16px 16px 16px;
}

.base-page-green {
	font-size: 24px;
	color: $primary-color;
	text-align: center;
	font-weight: bold;
}

.base-page-warning {
	font-size: 24px;
	color: red;
	text-align: center;
}

.base-page-grey {
	font-size: 14px;
	color: #9b9b9b;
	text-align: center;
}

.register-big-image {
	height: 124px;
	width: 193px;
}

.register-confirmation-big-image {
	height: 144px;
	width: 144px;
}

.check-email-img-padding {
	padding: 50px 0 25px 0;
}

.check-email-text-padding {
	padding: 0px 30px 40px 30px;
}

.benefit-grid {
	background: white;
	width: 100%;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05);
}

.benefit-item {
	width: 260px;
	height: 130px;
}

.blocked-page {
	span {
		text-align: center;
	}

	img {
		height: 70px;
	}
}

.primary-border {
	border: solid 1px $primary-color;
}

.conversion-calculator md-input-container {
	margin-bottom: 0 !important;
}

.white-box {
	background-color: rgb(255, 255, 255);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
	border-radius: 5px;
}

.map-text {
	font-weight: 500;
	color: $dark-grey;
	text-align: center;
}

client-property md-select {
	padding-bottom: 6px !important;
}

.report-chart i {
	color: black;
}

md-button-group {
	border-radius: 3px;
	display: inline-block;
	padding: 0;
	margin: 6px 8px;
	min-height: 36px;
}

md-button-group.md-raised:not([disabled]) {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

md-button-group > .md-button {
	border-radius: 0;
	border: 0;
	min-width: 20px;
	margin: 0;
}

md-button-group .md-button.selected {
	background-color: rgba(158, 158, 158, 0.2);
}

grid-components disposal-tech md-input-container {
	margin: 0px;
}

.input-contaters-default-margin md-input-container {
	margin-top: 8px;
	margin-bottom: 8px;
}

.error-label {
	font-size: 12px;
	color: $danger-color;
	max-width: 450px;
}

.error-label md-icon {
	font-size: 16px;
	padding: 3px;
	color: $danger-color;
}

.md-button.md-primary.light-grey {
	background-color: #f4f4f4 !important;
}

.md-button.button-neutral-light-2 {
	color: $secondary-color;
	text-transform: none !important;
	background-color: $neutral-light-2-color;
	&:hover {
		background-color: white;
	}
}

[disabled] md-input-container.white-disabled .md-input,
md-input-container.white-disabled .md-input[disabled] {
	background: white !important;
}

.md-chip {
	margin-top: 3px !important;
}

md-menu-content {
	border-radius: 4px;
}

.invite-user-radio-group md-radio-button .md-container {
	top: 15% !important;
}

.client-recipient-messages {
	margin-top: -25px;
	margin-left: 15px;
	margin-bottom: 8px;
}

.action-plan-item {
	background-color: #f5f5f5 !important;
}

.action-plan-disabled-item {
	input {
		background-color: #ebebeb !important;
	}
	md-select-value {
		background-color: #ebebeb !important;
	}
}

.status-card {
	height: 96px;
	width: 320px;
}

.status-card-success {
	background-color: $strong-green;
	.status-card-text {
		color: $strong-green;
		background-color: $light-green;
	}
}

.status-card-warning {
	background-color: $orange-color;
	.status-card-text {
		color: $orange-color;
		background-color: $light-yellow;
	}
}

.status-card-error {
	background-color: $danger-color;
	.status-card-text {
		color: $danger-color;
		background-color: $light-red;
	}
}

.notification-blue {
	height: 10px;
	width: 10px;
	line-height: 10px;
	border-radius: 50%;
	position: relative;
	right: 22px;
	background: rgba(74, 144, 226, 0.5);
	color: white !important;
}

.md-icon-button.normal-button-border {
	border-radius: 3px !important;
}

.search-tab-textcomplete {
	md-autocomplete {
		box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		margin-left: 38px !important;
		margin-right: 38px !important;
	}
}
.search-tab li {
	font-size: 12px !important;
	margin-bottom: 0px !important;
	height: 40px !important;
	min-height: 40px !important;
	max-height: 40px !important;
}

.search-tab li:last-child {
	border-bottom-width: 0;
}

.search-tab .item-title,
.search-tab .item-metadata {
	display: block;
	line-height: 2;
}

.filters md-autocomplete {
	margin-right: 0px !important;
}

md-icon.qualification {
	color: $blue-color;
}

.qualification.material-icons {
	font-size: 15px !important;
	margin-top: 0px;
}

.location {
	font-size: 11px;
}

.price {
	font-size: 18px;
	color: $success-color;
}

chips input {
	background-color: none;
	border: none !important;
	border-width: none !important;
}

.chips-reviewed {
	max-height: 130px;
	overflow: auto;
}

md-chips.remove-chips-input input {
	display: none;
}

md-datepicker input {
	max-width: 312px;
}

chips input:focus {
	border-width: 0px !important;
}

.no-shadow md-chips-wrap {
	box-shadow: none !important;
}

chips md-chips-wrap,
md-datepicker {
	background-color: $light-blue;
}

.list-item {
	line-height: 14px !important;
}

.list-item:hover,
.list-item label:hover {
	background-color: $light-blue;
	cursor: pointer !important;
}

.list-item:focus {
	outline: 0 !important;
}

.sub-item {
	color: rgba(0, 0, 0, 0.54) !important;
}

.sub-item-invalid {
	color: $danger-color !important;
}

.sub-item,
.sub-item-invalid {
	font-size: 11px;
}

md-list-item {
	border-bottom: 1px solid $light-blue !important;
}

md-icon.Filter {
	color: rgba(0, 0, 0, 0.34) !important;
}

md-icon.success {
	color: $success-color !important;
}

md-tooltip ._md-content {
	height: auto;
	width: 400px;
	max-width: 400px;
	padding: 8px;
	white-space: initial;
}

md-autocomplete-wrap {
	box-shadow: none !important;
}

input.mr-text-field-no-float,
.filters textcomplete input {
	background-color: white !important;
	border-width: 0px;
	height: 40px;
	outline: none;
}

main-content {
	padding: 10px !important;
}

vgr-container h5 {
	padding-left: 0px !important;
}

.search {
	background-color: $light-blue !important;
	border-width: 0px !important;
	height: 40px;
}

vgr-container.mr-container button {
	color: white;
	line-height: 0px !important;
}

.fa.fa-spin {
	color: rgba(0, 0, 0, 0.54);
}

.link {
	color: white;
	cursor: pointer;
}

.link:hover {
	color: white;
}

.fill {
	margin: 0;
	width: 100%;
	min-height: 100%;
	height: 100%;
}

md-tab-content > div:first-child {
	@extend .fill;
}

md-tooltip .md-content {
	height: auto;
	max-width: 400px !important;
}

md-tooltip.break-text {
	.md-content {
		text-overflow: clip;
		white-space: normal;
		text-align: justify;
	}
}

.listTitle {
	font-size: 14px;
	color: #4a4a4a;
	font-style: normal;
	text-decoration: underline;
}

.min-height-500-ie {
	min-height: 500px;
}

.max-height-195 {
	max-height: 195px;
}

.b-t-05 {
	border-top: 0.5px solid;
	padding-top: 8px;
	margin: 0 16px;
}

.material-icons.material-icons-info {
	color: $blue-color;
	cursor: pointer;
	margin-left: 5px;
}

.material-icons.material-icons-danger {
	color: $danger-color;
	cursor: pointer;
	margin-left: 5px;
}

.material-icons.material-icons-gray {
	color: rgba(0, 0, 0, 0.26);
	cursor: pointer;
	margin-left: 5px;
}

.size-40 {
	height: 40px;
	min-height: 40px;
	max-height: 40px;
	width: 40px;
	min-width: 40px;
	max-width: 40px;
}

.bg-primary-color-i {
	background-color: $primary-color;
}

.bg-warning-color-i {
	background-color: $orange-color !important;
}

.border-grey {
	border: 1px solid #b9b7b7;
}

.button-link-i {
	&:hover {
		background-color: transparent !important;
		text-decoration: underline;
	}
}

.canvas-form-margin {
	margin: 10px 10px 40px 10px !important;
}

.two-steps-scaling-title {
	margin-left: 20px;
	color: #9b9b9b;
	font-weight: 500;
}

.vertical-center-loading {
	position: absolute;
	top: 50%;
}

.white-bg {
	background: white;
}

.ellipsis-wrapper {
	overflow: hidden;
}

.ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	&--max-w90 {
		max-width: 90%;
	}
}

.affix {
	position: fixed;
}

.filters md-select.mr-select {
	margin: 10px 0px 10px 10px !important;
	background-color: $light-blue !important;
	border-bottom: 0px !important;
	height: 40px;
}

.filters .md-select-value {
	border-bottom: 0px !important;
}

.filters {
	padding-right: 8px;
	padding-left: 8px;
}

.residue-filter-popup {
	md-radio-button {
		.md-container {
			.md-off {
				border-color: $strong-green;
			}
			.md-on {
				background-color: $strong-green;
			}
		}
	}
}

.select-filter-component {
	font-size: 16px;
	font-weight: 400;
	position: relative;
	float: left;
	border-radius: 16px;
	border: 1px solid $primary-color;
	margin: 8px;
	padding: 0 12px;
	text-transform: uppercase;
	background-color: white !important;
	color: $primary-color;
}

.select-filter-component-active {
	background-color: $primary-color !important;
	color: white;
}