@import "../css/default-config.scss";

$default-padding: 16px;
$default-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05);

#disposal-popup md-dialog {
	md-input-container {
		padding: 0 $default-padding;
		height: 34px;
		label {
			padding-left: $default-padding + 6px !important;
		}
		&.height-auto {
			height: auto;
		}
	}

	legend {
		margin-left: $default-padding;
		margin-right: $default-padding;
	}

	.disposal-textcomplete {
		md-autocomplete-wrap md-progress-linear .md-mode-indeterminate {
			top: 20px;
			left: 15px;
		}
	}

	md-switch {
		color: #4a4a4a;
		font-size: 12px;
		height: 12px;
		padding: 16px;
	}
}

#angular-popup md-dialog {
	height: 80%;
}
#angular-popup-small md-dialog {
	height: 430px;
}

@media (min-width: 950px) {
	#disposal-popup-small md-dialog {
		min-width: 450px !important;
		max-width: 450px !important;
	}
	#disposal-popup-medium md-dialog {
		min-width: 680px !important;
		max-width: 680px !important;
	}
}

.popup-title {
	color: $gray-color;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: bold;
	padding: $default-padding $default-padding 0 $default-padding;
	&--normal-opacity {
		color: black;
		opacity: 0.87;
		text-transform: none;
	}
}

.popup-subtitle {
	color: #000000;
	opacity: 0.6;
	font-size: 14px;
	padding: 8px $default-padding 0 $default-padding;
}

.default-shadow {
	box-shadow: $default-shadow;
}

.flow-item {
	width: 141px;
	height: 115px;
}

.flow-current-stage {
	border-bottom: 2px solid;
}

.slip {
	background: rgba(237, 237, 237, 0.5);
	box-shadow: $default-shadow;
}

.step-image {
	height: 66px;
	width: 66px;
}

.slip-step-icon {
	width: 20px;

	i {
		color: $gray-color;
		font-size: 18px;
	}
}

.slip-step-inactive {
	font-size: 12px;
	font-weight: bold;
	color: $gray-color;
}

.slip-step-active {
	color: #4a4a4a;
}

.slip-step-value {
	font-size: 12px;
	padding-left: 40px;
	white-space: pre-wrap;
	color: $gray-color;
}

.slip-popup-icons {
	color: $primary-color;
	padding: $default-padding;

	i {
		padding: 0 4px;
		outline: none;
	}
}

.destination-step-title {
	font-size: 14px;
	font-weight: bold;
	color: #4a4a4a;
	margin-bottom: 8px;
	margin-top: 8px;
	line-height: normal;
}

.form-disposal-grid {
	margin-top: 5px;
	margin-bottom: 25px;
}

.destination-forms-container {
	padding-left: 16px;
	padding-right: 16px;
}

.destination-stage-completed {
	padding: 32px;
	font-size: 24px;
	font-weight: 700;
}

destination-data {
	md-input-container.md-input-has-value {
		label {
			padding-left: 6px;
		}
	}

	.disposal-textcomplete {
		md-input-container {
			height: 100%;
		}
	}

	.subtitle {
		padding-left: 16px;
	}

	md-switch {
		color: #4a4a4a;
		font-size: 12px;
		height: 12px;
		padding: 16px;
	}
}

.disposal-audit-label {
	color: rgba(0, 0, 0, 0.54);
	font-weight: 500;
	font-size: 11px;
}

.disposal-percent-alert {
	border: 1px solid $dark-orange-color;
	background-color: $dark-orange-color;
	border-radius: 5px;
	padding: 4px;
	margin: 8px $default-padding;

	span {
		color: white;
		font-size: 12px;
	}
}

.disposal-pendency-alert-icon {
	color: $blue-color;
	margin: 5px 0 0 5px;
}

.validation-error-description {
	color: $danger-color;
	font-size: 10px;
	font-weight: bold;
	padding: 0 8px;
}

.validation-confirm-description {
	color: $strong-green;
	font-size: 10px;
	font-weight: bold;
	padding: 0 8px;
}

.validation-error-buttton {
	background-color: $danger-color !important;
}

.disposal-add-icon {
	font-size: 32px;
	color: $primary-color;
	padding-right: $default-padding;
}

.report-popup-images-label {
	color: #9b9b9b;
	font-size: 14px;
	font-weight: bold;
}

.cost-popup-switch-label {
	color: $primary-color;
}

.cost-popup-padding {
	md-input-container {
		padding: 0 8px !important;

		label {
			padding-left: 8px !important;
		}
	}
}

.cost-popup-border {
	margin-top: 20px;
	border-top: #9b9b9b 1px solid;
}
.disposal-mobile {
	overflow: visible;

	destination-data {
		background-color: white;
	}
	destination-actions {
		background-color: white;
	}
}

.disposal-cost-information-image {
	box-shadow: 3px 1px 7px 0 rgba(0, 0, 0, 0.26);
	border-top-right-radius: 9px;
	border-bottom-right-radius: 9px;
	border: 1px solid $strong-green;
	background-color: $strong-green;
	width: 46px;
	height: 47px;
	z-index: 2;

	img {
		width: 28px;
		height: 18px;
	}
}

.disposal-cost-information-text {
	box-shadow: 3px 1px 7px 0 rgba(0, 0, 0, 0.26);
	z-index: 1;
	border-top-right-radius: 9px;
	border-bottom-right-radius: 9px;
	margin-top: 3px;
	border: 1px solid white;
	background-color: white;
	padding: 4px;
	width: 188px;
	height: 41px;

	span {
		margin-right: 4px;
		font-size: 13px;
	}
}

.stock-image {
	height: 65px;
	margin-bottom: 8px;
}

.disposal-action-image {
	height: 90px;
	padding: 16px;
}

.retry-integration {
	height: 36px;
	width: 36px;
	min-height: unset;
	min-width: unset;

	i {
		font-size: 18px;
	}
}

.retry-integration.retry-integration-white {
	background: white;

	i {
		color: $primary-color;
	}
}

.retry-integration.retry-integration-white:hover {
	background: $light-grey;
}

.disposal-confirmation-padding {
	padding: 64px 32px 32px 32px;
}

.disposal-integration-popup-system {
	.disposal-integration-details button {
		font-size: 14px;

		em {
			font-size: 22px;
		}
	}

	.disposal-integration-status {
		min-height: 40px;
		justify-content: center;

		.disposal-integration-status-label {
			font-weight: bold;
		}
	}

	.destination-system-card {
		min-height: 52px;
		background-color: #eaeaea;
		border-radius: 4px;
		padding: 6px 16px;
		.destination-icon-button {
			min-width: 40px;
			min-height: 40px;
			margin-left: 0px;
			margin-right: 0px;
		}
	}

	.destination-error-message {
		color: $color-negative-bright;
		font-size: 12px;
	}
}

.disposal-integration-source {
	margin-bottom: 10px;

	md-checkbox {
		margin-bottom: 5px;
	}

	.disposal-integration-source-description {
		white-space: nowrap;
	}

	information-small {
		margin-left: 10px;
		vertical-align: text-bottom;
	}

	.disposal-integration-source-success {
		color: $primary-color;
		margin-bottom: 16px;
		margin-left: 10px;
		margin-top: 2px;

		em.material-icons {
			font-size: 16px;
			margin-right: 2px;
		}

		span {
			font-size: 12px;
			font-weight: 500;
			padding-top: 2px;
		}
	}

	.disposal-integration-source-beta-text {
		color: #9b9b9b;
		font-size: 12px;
		font-style: italic;
		font-weight: 100;
		margin-bottom: 10px;
		margin-left: 30px;
	}

	.disposal-integration-source-error {
		margin-bottom: 20px;
	}
}

.destination-add-button {
	display: flex;
	justify-content: center;
	min-height: auto;
	&.md-button.md-fab {
		width: 20px;
		height: 20px;
		box-shadow: none;
	}
}

.destination-add-icon {
	font-size: 14px !important;
	width: auto;
	height: auto;
}

.destination-card {
	height: 131px;
	transition: 0.3s;
	box-shadow: none;
	border: 1px solid #0000001f;
	border-radius: 4px;
	line-height: normal;
	&:hover {
		box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
		cursor: pointer;
	}
}

.item-alert {
	border-radius: 20px;
	background-color: #e9f0f8;
	color: #4a90e2;
	margin: 2px;
	padding: 4px 10px;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
}

.disposal-invoice-chip {
	md-chip {
		height: auto !important;
		line-height: 26px !important;
		font-size: 12px;
		margin: 0px 16px !important;
	}

	md-chips-wrap {
		padding: 0px;
	}

	.valid {
		background-color: $primary-lightest !important;
		color: $primary-color !important;
		font-weight: 500;
	}

	.canceled {
		background-color: $negative-lightest !important;
		color: $danger-color !important;
		font-weight: 500;
	}
}

.disposal-popup-actions {
	.md-button:disabled {
		z-index: -1;
	}
}

.cost-module-alert{
	padding: $default-padding $default-padding 0 $default-padding;
	color: $neutral-dark-color;
}

.cost-module-info-message{
	padding-top: $default-padding;
}