.dmr-alert-popup {
	width: 902px;
	min-height: 564px;
	color: #1f4b60;
}
.dmr-alert-popup a {
	color: #1f4b60;
}
.dmr-alert-popup .popup-content {
	flex: 1;
	display: flex;
	flex-flow: column;
}
.dmr-alert-popup .popup-content .popup-main-content {
	position: relative;
	padding: 36px 36px 0px 36px;
	min-height: 374px;
	flex: 1;
	background: linear-gradient(36deg, #dfe5e8 0%, #7ce4eb 100%);
}
.dmr-alert-popup .popup-content--buttons {
	margin-bottom: 24px;
	margin-right: 30px;
	margin-top: 64px;
}
.dmr-alert-popup .popup-content--title {
	margin: 0px;
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	color: #1e4960;
}
.dmr-alert-popup .popup-content .image-popup {
	padding: 36px 48px;
	position: absolute;
	left: 0;
	z-index: 1;
	bottom: -64px;
}
.dmr-alert-popup .popup-content .image-popup--img {
	max-width: 552px;
}
.dmr-alert-popup .popup-content .card-popup {
	padding: 36px 48px;
	position: absolute;
	right: 0;
	z-index: 1;
	bottom: -48px;
	padding-bottom: 16px;
}
.dmr-alert-popup .popup-content .card-popup--img {
	width: 346px;
	height: 258px;
}
.dmr-alert-popup .popup-content .card-popup--item {
	width: 424px;
	margin: 0px 0px 8px 0px;
	padding: 0px;
	box-shadow: 0px 2px 3px #00000029;
	border-radius: 4px;
}
.dmr-alert-popup .popup-content .card-popup--item--title {
	overflow: hidden;
	padding: 16px;
	text-overflow: ellipsis;
}
.dmr-alert-popup .popup-content .card-popup--item--title--text {
	color: #1f4b60;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	gap: 16px;
}
.dmr-alert-popup .popup-content .card-popup--item--title--text--icon-bg {
	background-color: #f9f3f7;
	border-radius: 50%;
	width: 48px;
	height: 48px;
}
.dmr-alert-popup .popup-content .card-popup--item--title--text--icon {
	font-size: 24px;
	width: 48px;
	height: 48px;
	align-items: center;
	justify-content: center;
	display: flex;
	color: #901663;
}
.dmr-alert-popup .popup-content .card-popup--item--title--text span > p {
	margin: 0;
}
.dmr-alert-popup .popup-content .card-popup--item--title--text span {
	display: block;
	color: #1f4b60;
	cursor: default;
}
.dmr-alert-popup .popup-content .card-popup--item--title--text span > ul {
	margin: 0;
	padding-inline-start: 20px;
}
@media only screen and (max-width: 1024px) {
	md-dialog.md-dialog-fullscreen {
		min-height: auto !important;
	}
	.dmr-alert-popup {
		width: auto;
	}

	.dmr-alert-popup .popup-content {
		padding: 0px !important;
	}
	.dmr-alert-popup .popup-content .popup-main-content {
		padding: 16px;
	}

	.dmr-alert-popup .popup-content--title {
		font-size: 18px !important;
	}
	.dmr-alert-popup .popup-content--buttons {
		margin: 8px !important;
		display: flex !important;
		flex-flow: column !important;
	}
	.dmr-alert-popup .popup-content .card-popup {
		margin-top: 18px !important;
		display: flex;
		position: relative;
		padding: 0px 8px;
		bottom: 0px;
	}
	.dmr-alert-popup .popup-content .card-popup--item {
		width: 100%;
	}
	.dmr-alert-popup .popup-content .image-popup .image-popup--img {
		display: none;
		padding: 0px;
	}
}
