@import "../../../css/default-config.scss";

.general-top-alert-popup {
	width: 902px;
	.popup-content {
		.popup-main-content {
			position: relative;
			padding: 36px 36px 0px 36px;
			min-height: 374px;
			background: transparent linear-gradient(293deg, #ADF5F7 0%, #1C4E76 100%) 0% 0% no-repeat padding-box;
		}
		&--buttons {
			margin-bottom: 30px;
			margin-right: 30px;
			margin-top: 30px;
		}
		&--title {
			margin: 0px;
			max-width: 500px;
			text-align: center;
			font-size: 22px;
			line-height: 28px;
			font-weight: bold;
			color: white;
		}
		.image-popup{
			padding: 28px 48px;
			position: absolute;
			left: 0;
			z-index: 1;
			&--img{
				max-width: 552px;
			}
		}
		.card-popup {
			padding: 0 30px;
			position: absolute;
			gap: 47px;
			bottom: 15px;
			right: 0;
			&--img {
				width: 484px;
				height: 258px;
			}
			&--item {
				width: 274px;
				height: 88px;
				margin: 0px 0px 8px 0px;
				padding: 0px;
				box-shadow: 0px 2px 3px #00000029;
				border-radius: 4px;
				&--title {
					overflow: hidden;
					padding: 16px;
					text-overflow: ellipsis;
					&--text {
						color: $primary-color;
						font-size: 13px;
						gap: 16px;
						&--icon-bg {
							background-color: #f9f3f7;
							border-radius: 50%;
							width: 48px;
							height: 48px;
						}
						&--icon {
							font-size: 24px;
							width: 48px;
							height: 48px;
							align-items: center;
							justify-content: center;
							display: flex;
							color: $primary-color;
						}
						span > p {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	md-dialog.md-dialog-fullscreen {
		min-height: auto !important;
	}
	.popup-content {
		padding: 30px 30px 0px 30px !important;
		&--title {
			font-size: 18px !important;
		}
		&--buttons {
			margin-top: 20px !important;
		}
		.card-popup {
			margin-top: 18px !important;
			&--img {
				display: none;
			}
		}
	}
}
