@import "../../../css/default-config.scss";

.maintenance-popup {
	height: 607px;
	line-height: 1;
	.main-content {
		width: 958px;
		height: 476px;
		.maintenance-card {
			padding: 40px;
			width: 868px;
			height: 371px;
			background-color: $color-warning-alert;
			color: $primary-color;
			&--title {
				font-size: 24px;
				color: #ffffff;
			}
			&--description {
				font-size: 20px;
			}
			.card-icon-bg {
				background-color: $primary-color;
				margin-right: 24px;
			}
			.card-icon {
				color: #ffffff;
			}
			p {
				margin-bottom: 24px;
			}
		}
		.maintenance-support-card {
			position: absolute;
			right: 37px;
			bottom: 80px;
			height: 80px;
			width: 340px;
			box-shadow: 0px 2px 3px #00000029;
			border-radius: 4px;
			color: $primary-color;
			span {
				padding-right: 24px;
			}
			.card-icon-bg {
				background-color: $primary-lightest;
				margin: 0 16px 0 24px;
			}
			.card-icon {
				color: $primary-color;
			}
		}
		.card-icon-bg {
			background-color: $primary-lightest;
			border-radius: 50%;
			width: 48px;
			height: 48px;
		}
		.card-icon {
			font-size: 24px;
			width: 48px;
			height: 48px;
			align-items: center;
			justify-content: center;
			display: flex;
			color: $primary-color;
		}
	}
	.maintenance-buttons {
		margin: 0 37px 12px 0;
	}
}

@media only screen and (max-width: 800px) {
	.maintenance-popup {
		font-size: 14px !important;
		.maintenance-card {
			padding: 8px !important;
			&--title {
				font-size: 16px !important;
			}
			&--description {
				font-size: 16px !important;
			}
		}
		.card-icon-bg {
			display: none;
		}
		.maintenance-support-card {
			width: 70% !important;
			padding: 8px !important;
			span {
				padding-right: 0 !important;
			}
		}
	}
}
