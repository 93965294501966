@import "../css/default-config.scss";

.destination-followup-details-container :hover {
    background-color: #efefef;
}

.destination-followup-details-container {
    padding-bottom: 10px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.destination-followup-details {
    border: 1px solid rgba(155, 155, 155, 0.2);
    border-radius: 5px;
    outline: none;
    position: relative;
    color: #acacac;
    transition: filter 1s;
}

.destination-followup-details .minor-text {
    color: #9b9b9b;
    font-size: 10px;
}

.destination-followup-details .normal-text {
    color: #4a4a4a;
    font-size: 14px;
}

.destination-followup-details .major-text {
    color: #4a4a4a;
    font-size: 16px;
}

.destination-followup-details strong {
    white-space: pre-wrap;
}

.destination-followup-details > .btn-print {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.destination-followup-details .lock-alert {
    display: flex;
    flex-wrap: wrap;
    margin-left: 6px;
    align-items: center;
    font-size: 18px;
    color: $danger-color;
}

.destination-followup-details .alerts {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 5px;
}

.destination-followup-details .alerts .item-alert {
    border-radius: 20px;
    background-color: #e9f0f8;
    color: $blue-color;
    margin: 2px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.destination-followup-details .alerts .icon-alert {
    display: flex;
    align-items: center;
    font-size: 25px;
}

.destination-followup-details .alerts .icon-alert.icon-alert-orange {
    color: orange;
}

.destination-followup-details .alerts .icon-alert.icon-alert-green {
    color: $success-color;
}

.destination-followup-details .alerts .item-alert > span {
    margin-left: 3px;
}

.destination-followup-details .alerts .warning {
    display: flex;
    align-items: center;
    justify-content: center;
}

.destination-followup-details .alerts .warning > md-icon,
.destination-followup-details .alerts .warning > em {
    padding-left: 5px;
}

.status-title {
    padding: 0px;
    color: #4a4a4a;
    font-weight: 500;
    background: #ebebeb;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.status-title span {
    line-height: 48px;
}

.status-title span.with-button {
    margin-left: 45px;
}

.new-button {
    min-width: 30px;
    border-radius: 50%;
    display: inline;
    float: right;
}

destination-followup-accordion v-pane-header {
    background: #ebebeb;
    border-radius: 5px;
}

destination-followup-accordion v-pane-header h5.without-button {
    line-height: 34px;
}

.destination-followup-list {
    border: 1px solid rgba(155, 155, 155, 0.2);
    overflow-x: hidden;
}

.destination-followup-details-container .md-button.md-icon-button.btn-print:hover {
    background-color: inherit;
    color: $primary-color;
}
