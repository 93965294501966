@import "../default-config.scss";

$login-gray-color: #d0d0d0;

.login-options-container {
	width: 100%;

	.login-options-container-logo {
		margin-top: 10px;
	}

	md-card-title-text {
		text-align: center;

		.main-text {
			color: $primary-color;
			font-size: 24px;
			font-weight: bold;
		}

		.secondary-text {
			color: $gray-color;
			font-size: 14px;
		}
	}

	.register-input > div {
		width: 100%;

		div { 
			width: 100%;
		}
	}

	.register-input > .column > div {
		width: 100%;
	}

	.buttons-container {
		width: 100%;

		action-button {
			flex: 1;
		}

		button {
			flex: 1;
			font-size: 12px;
			text-transform: uppercase;
		}

		button.login-sso-button {
			color: $primary-color;
			border: 1px solid $primary-color;
		}

		button.login-sso-button:hover {
			color: white;
			background-color: $primary-color-fade;
		}

		button.button-link {
			flex: unset;
			font-size: 12px;
			text-transform: none;
			text-decoration: underline;
		}
	}

	.title-line {
		margin-top: 15px;
	}

	.bottom-policy-label {
		margin-top: 10px;

		img {
			width: auto;
		}

		span {
			font-size: 12px;
			margin-top: 15px;
			padding-right: 10px;
			text-align: center;
		}
	}

	.accept-terms-and-conditions {
		margin-left: 5px;
		margin-top: 5px;

		span {
			font-size: 12px;
			margin-top: 2px;

			a {
				color: black;
				font-weight: bold;
			}
		}
	}

	.register-profile-box {
		width: 164px;
		height: 144px;
		max-width: 164px;
		max-height: 144px;
		border-radius: 2px;
		border-top-color: white;
		border-top-style: solid;
		border-top-width: 2px;
		box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.2);
		margin: 10px;
		outline: none;

		.register-profile-image {
			width: 64px;
			height: 64px;
		}

		.register-profile-image-text {
			color: $light-grey;
			font-size: 15px;
			font-weight: bold;
			margin-top: 10px;
			text-align: center;
		}

		.register-profile-image-text.register-profile-image-text-selected {
			color: $primary-color;
		}
	}

	.register-profile-box.register-profile-box-selected {
		border-top-color: $primary-color;
	}

	.legal-documents {
		padding: 16px 16px 0 16px;
		a {
			font-size: 12px;
			color: $primary-color;
		}
	}
}

.register-input > div > md-input-container {
	margin: 5px;
	height: 40px;
	max-height: 40px;
	border-radius: 4px;
	border: solid 1px $login-gray-color;
}

.register-input > div > md-input-container.md-input-focused {
	border: solid 1px $primary-color;
}

.register-input .register-input-invalid div > md-autocomplete {
	border: solid 1px $danger-color;
}

.register-input > div > md-input-container.register-input-invalid {
	border: solid 1px $danger-color;
}

.register-input > div > md-input-container.md-input-focused label {
	display: none;
}

.register-input > div > md-input-container.md-input-has-value label {
	display: none;
}

.register-input > div > md-input-container input {
	border: 0;
}

.register-input > div > md-input-container .md-errors-spacer {
	display: none;
}

.register-input > div > md-input-container i {
	border: 0;
	margin-top: 3px;
	color: $login-gray-color;
}

.register-input > div > md-input-container.md-input-focused i {
	color: $primary-color;
}

.register-input div > md-autocomplete {
	width: 97%;
	margin: 5px;
	padding: 0 2px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px $login-gray-color;

	input {
		padding: 0;
	}

	input::-webkit-input-placeholder {
		color: $login-gray-color;
	}
}

.register-input input:-webkit-autofill:hover {
	-webkit-text-fill-color: black;
}

md-select.email-select {
	border-radius: 10px;
	border: solid 1px $light-grey;

	.md-select-value {
		border-bottom: 0;
	}

	span {
		color: $strong-green;
		font-style: italic;
	}

	.material-icons {
		color: $strong-green;
	}
}

.md-select-menu-container.email-select {
	span {
		color: $strong-green;
	}

	.material-icons {
		color: $strong-green;
	}

	md-option:hover {
		background-color: $light-grey;
	}
}
