@import "default-config.scss";

/* latin-ext */
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(/fonts/RobotoRegular-400-ext.woff2) format("woff2");
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(/fonts/RobotoRegular-400.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(/fonts/RobotoRegular-500-ext.woff2) format("woff2");
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(/fonts/RobotoRegular-500.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
	font-display: swap;
	font-family: "Material Icons";
	font-style: normal;
	font-weight: 400;
	src: local("Material Icons"), local("MaterialIcons-Regular"), url(/fonts/MaterialIcons-Regular.woff2) format("woff2"),
		url(/fonts/MaterialIcons-Regular.woff) format("woff"), url(/fonts/MaterialIcons-Regular.ttf) format("truetype");
}

vgr-page .main-content {
	padding: 0px !important;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 10px;
}

span,
p,
body,
html {
	font-family: "Roboto", "Helvetica Neue", "Helvetica", sans-serif;
}

html {
	background: #e8e8e8 !important;
	min-width: 100% !important;
}

input {
	padding-left: 5px;
}

a {
	cursor: pointer;
	color: black;
	font-weight: bold;
}

html,
body {
	height: 100%;
}

label {
	font-weight: 500;
}

fieldset legend {
	font-weight: 700;
	font-size: 15px;
}

.grid {
	height: 100%;
}

.material-icons {
	font-family: "Material Icons", sans-serif;
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: "liga";
}

.md-datepicker-input {
	color: black !important;
	background: transparent !important;
	width: 100% !important;
}

.md-datepicker-input-container {
	width: 100% !important;
}

.subtitle {
	font-size: 12px;
	font-style: normal;
	font-weight: normal;
	color: #9b9b9b;
}

.help-subtitle {
	font-size: 11px;
	font-weight: 100;
	font-style: italic;
}

.input-container-no-margin md-input-container {
	margin: 0 !important;
}

.align-icon {
	float: left !important;
	position: absolute;
}

md-toast {
	min-width: 35%;
}

.loading {
	.md-button[disabled] {
		color: #c1c1c0 !important;
		background-color: #e6e6e5 !important;
		i {
			color: #c1c1c0 !important;
		}
	}
}

button:disabled {
	color: rgba(0, 0, 0, 0.31) !important;
}

button:disabled md-icon {
	color: rgba(0, 0, 0, 0.31) !important;
}

.pop-up-toolbar {
	color: black !important;
	background-color: white !important;

	md-icon {
		color: black !important;
	}
}

vgr-page md-toolbar.reports-toolbar {
	color: black;
	background-color: white;
}

.popup-text {
	position: relative;
	padding-left: 25px;
	padding-right: 25px;
	text-align: justify;
	max-width: 661px;
	font-size: 12px;
	margin-bottom: 30px !important;
}

.initial {
	position: initial !important;
}

.tag {
	text-align: center;
	color: #828282 !important;
	border-radius: 16px;
	background-color: #d1d1d1;
	padding: 6px;
	margin: 5px;
}

md-select {
	padding-left: 2px;
}

md-list-item.img-slider {
	border-bottom: 0px !important;
}

.has-image {
	padding-left: 50% !important;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/* Disable animations */
md-tab-content.md-right:not(.md-active) {
	animation: none;
	-webkit-animation: none;
}

md-tab-content.md-left:not(.md-active) {
	animation: none;
	-webkit-animation: none;
}

md-tab-content.md-left:not(.md-active) * {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-tab-content.md-right:not(.md-active) * {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

.md-tab {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-slider .md-focus-thumb {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
	animation: none !important;
	-webkit-animation: none !important;
}
md-slider ._md-thumb {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}
md-option {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-dialog._md-transition-out {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-dialog._md-transition-in {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

.md-ripple.md-ripple-placed {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

.md-ripple-container,
.md-ripple-placed {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-checkbox ._md-icon {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-checkbox.md-default-theme.white-checkbox .md-icon,
md-checkbox.white-checkbox .md-icon {
	border-color: rgb(250, 250, 250);
}

md-tabs-wrapper md-next-button,
md-tabs-wrapper md-prev-button {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-pagination-wrapper {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

._md-subheader-wrapper:not(.md-sticky-no-effect) {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

._md-sticky-clone[sticky-state="active"]:not(.md-sticky-no-effect) ._md-subheader-inner {
	-webkit-animation: none !important;
	animation: none !important;
}

.md-button {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
	&--stroked {
		border: 1px solid;
		color: $primary-color;
	}
	&--normal {
		text-transform: none !important;
	}
}

md-input-container label {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-slider ._md-thumb-container,
._md-focus-ring,
._md-track-fill,
._md-thumb {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-input-container .md-char-counter,
md-input-container .md-input-message-animation {
	transition: none !important;
}

md-select-menu {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;

	//disable box shadow
	border: 1px solid rgba(0, 0, 0, 0.14) !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}

md-select-menu md-content {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

._md-select-menu-container._md-leave {
	transition: none !important;
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
}

md-tab-content {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

md-ink-bar.md-right {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

md-ink-bar.md-left {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

md-tab-content.md-right {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

md-tab-content.md-left {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/* Disable box shadows */
md-dialog {
	border: 1px solid rgba(0, 0, 0, 0.14) !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}

md-toast .md-toast-content {
	/**no border needed here**/
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	min-height: 56px;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
.transition-element.ng-hide-remove {
	transform: rotateX(90deg);
	transform-origin: top;
	transition: all 1s ease;
}

.transition-element.ng-hide-remove.ng-hide-remove-active {
	transform: rotateX(0);
}

.transition-element.ng-hide-add {
	transform: rotateX(0);
	transform-origin: top;
	transition: all 0.5s ease-in-out;
}

.transition-element.ng-hide-add.ng-hide-add-active {
	transform: rotateX(-90deg);
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
.lf-ng-md-file-input .lf-ng-md-file-input-preview-container .lf-ng-md-file-input-frame > img {
	height: auto !important;
	max-height: 200px !important;
	width: auto !important;
}

.lf-ng-md-file-input .lf-ng-md-file-input-preview-container .lf-ng-md-file-input-frame {
	height: auto !important;
}

.lf-ng-md-file-input {
	margin-bottom: 20px !important;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
vgr-container md-tabs-wrapper {
	background-color: white !important;
}

vgr-container md-tab-item span {
	color: black !important;
}

vgr-container md-tab-item.md-active {
	background-color: white !important;
}

vgr-container md-tab-item.md-active span {
	color: black !important;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
.md-chips.filter,
md-chips.filter .md-chips,
md-chips.filter .md-chips.focused,
md-chips .md-chips {
	box-shadow: none !important;
}

.md-chips {
	box-shadow: none !important;
}

.md-chips .md-chip-input-container,
.md-chips .md-chip {
	margin-top: 0px !important;
}

.md-chips.filter input,
md-chips.filter input {
	font-size: 20px;
}

.md-chips.filter md-chip:not(.md-focused),
md-chips.filter md-chip:not(.md-focused) {
	background: white !important;
}

md-chips.filter .md-chip.focused {
	background: grey !important;
}

md-chips.display-none md-autocomplete input {
	display: none;
}

.md-chips.filter md-chip md-icon,
md-chips.filter md-chip md-icon {
	color: grey !important;
}

chips md-chips-wrap,
md-datepicker {
	background-color: transparent !important;
}

md-chips.md-default-theme .md-chip,
md-chips .md-chip {
	background-color: #d1d1d1 !important;

	.left-border-regular-supplier {
		border-left: 5px #7ed321 solid !important;
	}

	.left-border-pendent-supplier {
		border-left: 5px #f5cd14 solid !important;
	}

	.left-border-irregular-supplier {
		border-left: 5px #d0021b solid !important;
	}

	.left-border-inactive-supplier {
		border-left: 5px gray solid !important;
	}
}

.chips-ddl {
	min-width: 400px !important;
	max-width: 1200px !important;
}

.md-chips .md-chip-input-container {
	min-width: 30% !important;
}

md-chips > md-chips-wrap > div > input {
	font-size: 20px;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
.input-error-message {
	font-size: 10px;
	margin-top: 0px !important;
	color: grey;
}

.input-error-message.invalid-required {
	color: $danger-color;
}

md-input-container label:not(.md-container-ignore).md-required:after {
	content: " *";
}

.custom-error {
	font-size: 12px;
	color: rgb(213, 0, 0);
}

form.ng-submitted .ng-invalid:not(ng-form),
form.ng-submitted .ng-invalid-required:not(ng-form),
form.ng-submitted input.ng-invalid,
form.ng-submitted md-select.ng-invalid-required,
form.ng-submitted md-datepicker.ng-invalid-required,
form.ng-submitted div.is-invalid md-chips-wrap,
form .ng-invalid-cpf-add,
form .ng-invalid-cpf,
form .ng-invalid-cnpj-add,
form .ng-invalid-cnpj {
	border: 1px solid $danger-color !important;
}

form.ng-submitted form.ng-invalid,
form.ng-submitted form.ng-invalid-required {
	border: 0px !important;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
.auto-complete {
	margin: 10px;
	background-color: white;
}

td .auto-complete {
	margin-left: 0px !important;
	background-color: transparent !important;
	border-bottom-width: 2px;
	border-bottom-style: inset;
	border-bottom-color: #b9b9b9;
}

td .auto-complete input {
	padding-left: 0px !important;
}

.autocomplete-custom-template li {
	height: auto;
	padding-top: 8px;
	padding-bottom: 8px;
	white-space: normal;
	min-height: 60px !important;
}

.autocomplete-custom-template li:last-child {
	border-bottom-width: 0;
}

.autocomplete-custom-template .item-title,
.autocomplete-custom-template .item-metadata {
	display: block;
	line-height: 2;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
.ui-notification {
	min-width: 500px !important;
	z-index: 2000000001 !important;
	white-space: pre-wrap;
}

.ui-notification.info.clickable:hover {
	opacity: 1 !important;
}
.ui-notification.warning {
	background-color: $color-warning-alert !important;
}
.ui-notification.warning.clickable:hover {
	opacity: 1 !important;
	background-color: $color-warning-alert-dark !important;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
.date-filter-grid {
	padding-top: 4px !important;
	margin: 0px !important;
}

.date-filter-grid label {
	margin-bottom: -5px !important;
}

.date-filter-grid md-select-value {
	border-bottom: 0px !important;
}

.date-field .md-errors-spacer {
	display: none;
}

.date-field button {
	min-width: unset;
}

.date-field md-icon {
	outline: none;
}
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
.grid-menu-box {
	min-width: 165px;
}

.grid-legend {
	padding-left: 20px;
	padding-bottom: 16px;
}

.primary-icon-button {
	color: $primary-color;
	background-color: white;
}

.secondary-icon-button {
	color: $secondary-color;
	background-color: white;
}

.danger-icon-button {
	color: $color-negative-bright;
	background-color: white;
	&--clicked {
		color: white;
		background-color: $color-negative-bright !important;
	}
}

.not-logged-in {
	#md-app-widget {
		display: none !important;
	}
}

md-tooltip.tooltip-multiline {
	height: auto;
	max-width: fit-content !important;
	white-space: break-spaces;

	.md-content {
		max-width: fit-content !important;
	}

	._md-content {
		max-width: fit-content !important;
	}
}

.display-unit-code-alert{
	md-input-container{
		margin-bottom: 0px;	
		
	}
	.md-errors-spacer{
		display: none;
	}	
}
.unit-code-alert{
	color: $color-warning-alert;
	margin-bottom: 18px;
	font-size: 12px;
}
